<template>
  <div class="class-paiement-lcr" v-if="this.item[this.computedId]">
    <div class="editable-input">
      <div
        class="edit-value"
        v-if="
          editing &&
            this.editable === true &&
            ((value == 'LCR' && item[chamNameComputed] == 0) || value != 'LCR')
        "
      >
        <div id="selectInput">
          <multiselect
            v-model="updateValue"
            placeholder="Rechercher"
            track-by="full_name"
            label="full_name"
            :options="computedTypePaiement"
            :searchable="true"
            select-label=""
            :max-height="150"
            class="selectDefault"
            :allow-empty="allowEmpty"
          >
            <span slot="noResult">Aucun {{ 'type paiement ' }} trouvé </span>
            <template slot="option" slot-scope="{ option }"
              ><strong>{{ option.full_name }} </strong>
            </template>
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong>{{ option.full_name }} </strong>
            </template>
            <span slot="noOptions">La liste est vide</span>
          </multiselect>
        </div>

        <div class="edit-action">
          <div class="action-icon validate" @click="update">
            <font-awesome-icon icon="check" class="fa-2x bell1" />
          </div>
          <div class="action-icon cancel" @click="cancel">
            <font-awesome-icon icon="times" class="fa-2x bell1" />
          </div>
        </div>
      </div>

      <div class="show-value" v-else @dblclick="edit()">
        {{ value }}
        <font-awesome-icon
          class="show-view-pencil ml-2"
          :class="{ 'show-editable-icon': showIconEdit == true }"
          icon="pencil-alt"
          v-if="
            this.editable === true &&
              ((value == 'LCR' && item[chamNameComputed] == 0) ||
                value != 'LCR')
          "
        />
      </div>
    </div>
    <div v-if="value == 'LCR'">
      <!-- <font-awesome-icon icon="eye" @click="GetDataLcrAction" /> -->
      <span
        v-if="value == 'LCR'"
        @click="GetDataLcrAction"
        class="countLcrIcon"
        >{{ item[chamNameComputed] ? item[chamNameComputed] : 0 }}</span
      >
    </div>

    <b-modal
      ref="paiement-modal"
      title="Paiement LCR"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      modal-class="modalLcrClass"
    >
      <div class="mb-1">
        <div
          class="modal-paiement-lcr"
          v-if="!getLoadingDataLcr && getDataLcr != null"
        >
          <div class="col-modal-1">
            <div class="block-montant-lcr mb-2">
              <span class="label-montant-lcr">Montant : </span>
              <span class="value-montant-lcr">
                {{ getDataLcr.montant_ttc }} €</span
              >
            </div>
            <div>
              <b-form-group
                label="Numéro de facture  "
                label-for="nm-f"
                class="input-modal-champ"
              >
                <b-form-input
                  autocomplete="off"
                  id="nm-f"
                  v-model="getDataLcr.numero"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Type de période *  "
                label-for="periode"
                class="input-modal-champ"
              >
                <b-form-select
                  id="periode"
                  class="b-form-select-raduis"
                  v-model="getDataLcr.type_de_periode"
                  :options="[
                    { value: 'Jour', text: 'Jour' },
                    { value: 'Semaine', text: 'Semaine' },
                    { value: 'Mois', text: 'Mois' }
                  ]"
                  @input="changeDate"
                  :disabled="!getDataLcr.can_update"
                ></b-form-select>
              </b-form-group>
            </div>

            <div>
              <b-form-group
                label="Nombre d'échéance * "
                label-for="nm-echeance"
                class="input-modal-champ"
              >
                <b-form-input
                  :min="
                    item[chamNameComputed] && item[chamNameComputed] != null
                      ? item[chamNameComputed]
                      : 0
                  "
                  type="number"
                  autocomplete="off"
                  id="nm-echeance"
                  v-model="echeance"
                  @input="changeChequeNumber"
                  :disabled="!getDataLcr.can_update"
                ></b-form-input>
              </b-form-group>
            </div>

            <div>
              <b-form-group
                type="number"
                label="Fréquence d'échéance *  "
                label-for="frequence"
                class="input-modal-champ"
              >
                <b-form-input
                  min="0"
                  type="number"
                  autocomplete="off"
                  id="frequence"
                  v-model="getDataLcr.frequence"
                  @input="changeDate"
                  :disabled="!getDataLcr.can_update"
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Date début * "
                label-for="date-debut"
                class="input-modal-champ"
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  v-model="getDataLcr.date"
                  class="inputDatePaiement"
                  @input="changeDate"
                  :clearable="false"
                  :editable="false"
                  :disabled="!getDataLcr.can_update"
                ></date-picker>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                v-if="rib"
                label="Rib * "
                label-for="rip-modal-paiement"
                class="input-modal-champ"
              >
                <!-- <multiselect
                  v-model="getDataLcr.rib.rib"
                  :options="[
                    {
                      iban: getDataLcr.iban,
                      swift: getDataLcr.swift,
                      rib: getDataLcr.iban + ' ' + getDataLcr.swift
                    }
                  ]"
                  :multiple="false"
                  label="rib"
                  track-by="rib"
                  required
                  class="customSelectSingleModal"
                  :showLabels="false"
                  :disabled="!getDataLcr.can_update"
                >
                </multiselect> -->
                <b-form-input
                  autocomplete="off"
                  id="rib-modal-paiement"
                  v-model="rib"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="error-lcr">{{ computedCheckMontant }}</div>
          </div>
          <div class="col-modal-2" v-if="echeance != null && echeance != ''">
            <template>
              <div
                class="item-cheque-lcr-clock"
                v-for="(item, index) in getDataLcr.lcr"
                :key="'cheque' + index"
              >
                <div class="sub-item-cheque-lcr-clock select-statut-block-val">
                  <b-form-group
                    v-if="item.numero"
                    label="Numéro *  "
                    :label-for="'num-fact' + index"
                    class="input-modal-champ mb-2 selecty-comp"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'num-fact' + index"
                      v-model="item.numero"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                  <font-awesome-icon
                    v-if="item.numero && !item.download_loading"
                    icon="arrow-alt-circle-down"
                    @click.prevent="downloadFacture(item)"
                    class="icon-style-color mr-1 icon-block-d"
                    :title="'Télécharger' + item.numero"
                  />
                  <div
                    class="loading icon-style-color mr-1 icon-block-d"
                    v-if="item.download_loading"
                  >
                    <div class="spinner-border" role="status"></div>
                  </div>
                </div>
                <div class="sub-item-cheque-lcr-clock">
                  <b-form-group
                    label="Montant * "
                    :label-for="'nm-echeance' + index"
                    class="input-modal-champ mb-1"
                  >
                    <b-form-input
                      type="number"
                      autocomplete="off"
                      :id="'nm-echeance' + index"
                      v-model="item.value"
                      @input="changeInput = true"
                      :disabled="!getDataLcr.can_update"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Date *  "
                    :label-for="'frequence' + index"
                    class="input-modal-champ mb-2"
                  >
                    <date-picker
                      value-type="format"
                      format="DD-MM-YYYY"
                      type="date"
                      v-model="item.date"
                      class="inputDatePaiement"
                      :disabled="!getDataLcr.can_update"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class="sub-item-cheque-lcr-clock">
                  <b-form-group
                    v-if="item.swift"
                    label="Swift *  "
                    :label-for="'swift-fact' + index"
                    class="input-modal-champ mb-2"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'swift-fact' + index"
                      v-model="item.swift"
                      :disabled="!getDataLcr.can_update"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="item.iban"
                    label="Iban *  "
                    :label-for="'iban-fact' + index"
                    class="input-modal-champ mb-2"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'iban-fact' + index"
                      v-model="item.iban"
                      :disabled="!getDataLcr.can_update"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="sub-item-cheque-lcr-clock">
                  <div
                    class="w-50 select-statut-block-val"
                    v-if="item.statut_vendeur"
                  >
                    <b-form-group
                      label="Statut vendeur "
                      :label-for="'stat-fact' + index"
                      class="input-modal-champ selecty-comp"
                    >
                      <b-form-select
                        :id="'stat-fact' + index"
                        class="b-form-select-raduis"
                        v-model="item.statut_vendeur"
                        :options="[
                          { value: 'a paye', text: 'LCR à payer' },
                          { value: 'paye', text: 'LCR payée' }
                        ]"
                        :disabled="!item.statut_vendeur_update"
                      ></b-form-select>
                    </b-form-group>
                    <font-awesome-icon
                      v-if="
                        item.statut_vendeur_update &&
                          !item.statut_vendeur_loading
                      "
                      icon="check"
                      class="icon-block"
                      @click="updateStatutVendeur(item)"
                      :title="'Valider le statut vendeur'"
                    />
                    <div
                      v-if="item.statut_vendeur_loading"
                      class="loading icon-block"
                    >
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </div>
                  <div
                    class="w-50 select-statut-block-val"
                    v-if="item.statut_acheteur"
                  >
                    <b-form-group
                      label="Statut client"
                      :label-for="'stat-fact' + index"
                      class="input-modal-champ selecty-comp"
                    >
                      <b-form-select
                        :id="'stat-fact' + index"
                        class="b-form-select-raduis"
                        v-model="item.statut_acheteur"
                        :options="[
                          { value: 'a paye', text: 'LCR à payer' },
                          { value: 'paye', text: 'LCR payée' }
                        ]"
                        :disabled="
                          item.statut_vendeur == 'paye' ||
                            !item.statut_acheteur_update
                        "
                      ></b-form-select>
                    </b-form-group>
                    <font-awesome-icon
                      v-if="
                        item.statut_acheteur_update &&
                          !item.statut_acheteur_loading
                      "
                      :title="'Valider le statut acheteur'"
                      class="icon-block"
                      icon="check"
                      @click="updateStatutAcheteur(item)"
                    />
                    <div
                      class="loading icon-block"
                      v-if="item.statut_acheteur_loading"
                    >
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </div>
                </div></div
            ></template>
          </div>
        </div>
        <div class="loader-data-lcr" v-else>
          <looping-rhombuses-spinner
            :animation-duration="1500"
            :size="66"
            :color="'#ff1d5e'"
          />
        </div>
        <div class="loader-data-lcr-item" v-if="loading">
          <looping-rhombuses-spinner
            :animation-duration="1500"
            :size="66"
            :color="'#ff1d5e'"
          />
        </div>
      </div>
      <div class="actionModel d-flex justify-content-center">
        <b-button
          class="button-valide-style"
          @click="paiementLCR"
          :disabled="getLoadingDataLcr || computedCheckMontant != null"
        >
          <span>
            {{ item[chamNameComputed] == 0 ? 'Créer' : 'Modifier' }}
            <div class="loading ml-2"></div>
          </span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { LoopingRhombusesSpinner } from 'epic-spinners';

import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    champName: { required: true },
    item: { required: true },
    value: { required: true },
    updateFunction: { required: true },
    editable: {
      default: true
    },
    showIconEdit: {
      default: true
    },
    allowEmpty: { default: true }
  },
  data() {
    return {
      editing: false,
      updateValue: { full_name: this.value },
      computedTypePaiement: [
        { value: 'Virement bancaire', full_name: 'Virement bancaire' },
        { value: 'Chèque', full_name: 'Chèque' },
        { value: 'LCR', full_name: 'LCR' }
      ],
      echeance: 0,
      changeInput: false,
      rib: null,
      loading: false
    };
  },
  components: {
    DatePicker,
    LoopingRhombusesSpinner
  },
  methods: {
    ...mapActions([
      'getPaiementLcrData',
      'createNewLcrFrais',
      'updateLcrFraisitem',
      'downloadLcr',
      'updateLcrFraiStatut'
    ]),
    changeChequeNumber() {
      if (this.getDataLcr.lcr.length > parseInt(this.echeance)) {
        let occ = this.getDataLcr.lcr.length - parseInt(this.echeance);
        let pos = parseInt(this.echeance);
        this.getDataLcr.lcr.splice(pos, occ);
      } else if (this.getDataLcr.lcr.length < parseInt(this.echeance)) {
        let occ = parseInt(this.echeance) - this.getDataLcr.lcr.length;
        for (let i = 0; i < occ; i++) {
          this.getDataLcr.lcr.push({
            value: this.computedMontantRest,
            date: null
          });
        }
      } else if (this.getDataLcr.lcr.length == 0) {
        this.changeInput = false;
      }

      for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
        if (this.changeInput == false && this.computedCheckNumLCR.length == 0) {
          this.getDataLcr.lcr[i].value =
            this.getDataLcr.montant_ttc / this.getDataLcr.lcr.length;
        }

        if (this.getDataLcr.date && this.getDataLcr.frequence) {
          if (i == 0) {
            this.getDataLcr.lcr[i].date = this.getDataLcr.date;
          } else {
            if (this.getDataLcr.type_de_periode) {
              let periodeType =
                this.getDataLcr.type_de_periode == 'Jour'
                  ? 'days'
                  : this.getDataLcr.type_de_periode == 'Semaine'
                  ? 'weeks'
                  : 'months';
              this.getDataLcr.lcr[i].date = moment(
                this.getDataLcr.lcr[i - 1].date,
                'DD-MM-YYYY'
              )
                .add(parseFloat(this.getDataLcr.frequence), periodeType)
                .format('DD-MM-YYYY');
            }
          }
        }
      }
    },
    changeDate() {
      for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
        if (this.getDataLcr.date && this.getDataLcr.frequence) {
          if (i == 0) {
            this.getDataLcr.lcr[i].date = this.getDataLcr.date;
          } else {
            if (this.getDataLcr.type_de_periode) {
              let periodeType =
                this.getDataLcr.type_de_periode == 'Jour'
                  ? 'days'
                  : this.getDataLcr.type_de_periode == 'Semaine'
                  ? 'weeks'
                  : 'months';
              this.getDataLcr.lcr[i].date = moment(
                this.getDataLcr.lcr[i - 1].date,
                'DD-MM-YYYY'
              )
                .add(parseFloat(this.getDataLcr.frequence), periodeType)
                .format('DD-MM-YYYY');
            }
          }
        }
      }
    },
    edit() {
      this.updateValue = { full_name: this.value };
      this.editing = true;
    },
    cancel() {
      this.editing = false;
      this.updateValue = { full_name: this.value };
    },
    async update() {
      if (this.value != this.updateValue.full_name) {
        var value = this.updateValue.full_name;
        await this.updateFunction({
          value: value,
          row: this.item,
          idFacture: this.item[this.computedId],
          column:
            this.champName == 'facture'
              ? 'type_paiement'
              : this.champName == 'facture_alteo'
              ? 'alteo_type_paiement'
              : this.champName + '_type_paiement',
          champ: this.champName
        });
        this.updateValue = { full_name: this.value };
        if (value == 'LCR') {
          this.GetDataLcrAction();
        }
      }
      this.editing = false;
    },
    async GetDataLcrAction() {
      this.$refs['paiement-modal'].show();
      const response = await this.getPaiementLcrData(
        this.item[this.computedId]
      );
      if (response) {
        this.echeance = this.getDataLcr.lcr.length;
        this.rib = this.getDataLcr.iban + ' ' + this.getDataLcr.swift;
        if (this.getDataLcr.type_de_periode == null) {
          this.getDataLcr.type_de_periode = 'Jour';
        }
        if (this.getDataLcr.frequence == null) {
          this.getDataLcr.frequence = 1;
        }
      }
    },
    async paiementLCR() {
      this.loading = true;
      const params = new FormData();
      params.append('facture_id', this.item[this.computedId]);
      params.append('frequence', this.getDataLcr.frequence);
      params.append('type_de_periode', this.getDataLcr.type_de_periode);
      params.append('iban', this.getDataLcr.iban);
      params.append('swift', this.getDataLcr.swift);
      for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
        params.append('LCRS[' + i + '][value]', this.getDataLcr.lcr[i].value);
        params.append(
          'LCRS[' + i + '][date]',
          moment(this.getDataLcr.lcr[i].date, 'DD-MM-YYYY').format('YYYY-MM-DD')
        );
        if (this.item[this.chamNameComputed] > 0) {
          if (this.getDataLcr.lcr[i].swift) {
            params.append(
              'LCRS[' + i + '][swift]',
              this.getDataLcr.lcr[i].swift
            );
          }
          if (this.getDataLcr.lcr[i].iban) {
            params.append('LCRS[' + i + '][iban]', this.getDataLcr.lcr[i].iban);
          }
          if (this.getDataLcr.lcr[i].id) {
            params.append('LCRS[' + i + '][id]', this.getDataLcr.lcr[i].id);
          } else {
            params.append('LCRS[' + i + '][id]', null);
          }
        }
      }
      if (this.item[this.chamNameComputed] == 0) {
        const responseC = await this.createNewLcrFrais(params);
        if (responseC.succes) {
          this.loading = false;
          this.$refs['paiement-modal'].hide();
          this.item[this.chamNameComputed] = responseC.data.lcr.length;
          this.echeance = 0;
          this.changeInput = false;
          this.rib = null;
          this.$swal.fire('', 'LCR créé avec succes', 'success');
        }
        this.loading = false;
      } else {
        const responseU = await this.updateLcrFraisitem(params);
        if (responseU.succes) {
          this.loading = false;
          this.$refs['paiement-modal'].hide();
          this.item[this.chamNameComputed] = responseU.data.lcr.length;
          this.echeance = 0;
          this.changeInput = false;
          this.rib = null;
          this.$swal.fire('', 'LCR modifier', 'success');
        }
        this.loading = false;
      }
    },
    async downloadFacture(item) {
      item.download_loading = true;
      const response = await this.downloadLcr(item.id);
      if (response) {
        item.download_loading = false;
      }
    },
    async updateStatutAcheteur(item) {
      item.statut_acheteur_loading = true;
      const response = await this.updateLcrFraiStatut({
        id: item.id,
        column: 'statut_acheteur',
        value: item.statut_acheteur,
        item: item
      });
      if (response.succes) {
        if (item.statut_acheteur == 'paye') {
          this.getDataLcr.can_update = false;
        }
        item.statut_acheteur_loading = false;
      }
    },
    async updateStatutVendeur(item) {
      item.statut_vendeur_loading = true;

      const response = await this.updateLcrFraiStatut({
        id: item.id,
        column: 'statut_vendeur',
        value: item.statut_vendeur,
        item: item
      });
      if (response.succes) {
        item.statut_acheteur = item.statut_vendeur;
        if (item.statut_vendeur == 'paye') {
          this.getDataLcr.can_update = false;
        }
        item.statut_vendeur_loading = false;
      }
    }
  },
  computed: {
    ...mapGetters(['getLoadingDataLcr', 'getDataLcr']),
    chamNameComputed() {
      if (this.champName == 'facture') {
        return 'lcr';
      } else if (this.champName == 'facture_alteo') {
        return 'alteo_lcr';
      }
      return this.champName + '_lcr';
    },
    computedMontantRest() {
      let montant = 0;
      for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
        montant += parseFloat(this.getDataLcr.lcr[i].value);
      }
      if (parseFloat(montant) < parseFloat(this.getDataLcr.montant_ttc))
        return this.getDataLcr.montant_ttc - montant;
      return 0;
    },
    computedCheckNumLCR() {
      return this.getDataLcr.lcr.filter(item => item.numero);
    },
    computedCheckMontant() {
      let montant = 0;
      if (this.getDataLcr != null) {
        for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
          montant += parseFloat(this.getDataLcr.lcr[i].value);
        }
        if (this.getDataLcr && this.getDataLcr.type_de_periode == null) {
          return 'Type de période obligatoiré';
        }
        if (this.getDataLcr && this.getDataLcr.frequence == null) {
          return "Fréquence d'écheance obligatoiré";
        }
        if (
          this.getDataLcr.lcr.filter(
            item => item.value == 0 || item.value == '0'
          ).length
        ) {
          return 'Montant LCR obligatoiré';
        }
        if (
          parseFloat(montant) != parseFloat(this.getDataLcr.montant_ttc) &&
          this.getDataLcr != null &&
          this.getDataLcr.lcr.length > 0
        )
          return "Vérifier vos montants s'il vous plaît, le total des chèques est supérieur au  montant";
      }
      return null;
    },
    computedId() {
      switch (this.champName) {
        case 'operationnel':
          return 'operationnel_id';
        case 'commercial':
          return 'commercial_id';
        case 'marketing':
          return 'marketing_id';
        case 'technique':
          return 'technique_id';
        case 'rge':
          return 'rge_id';
        case 'CDG':
          return 'CDG_id';
        case 'administratif_sav':
          return 'administratif_sav_id';
        case 'dsi':
          return 'dsi_id';
        case 'juridique':
          return 'juridique_id';
        case 'alger':
          return 'alger_id';
        case 'alteo_control_gestion':
          return 'alteo_control_gestion_id';
        case 'mairie':
          return 'mairie_id';
        case 'maintenance_pac':
          return 'maintenance_pac_id';
        case 'upcube':
          return 'upcube_id';
        case 'cactus':
          return 'cactus_id';
        case 'externe':
          return 'externe_id';
        case 'interne':
          return 'interne_id';
        case 'alteo':
          return 'alteo_id';
        case 'kynan':
          return 'facture_kynan_id';
        case 'facture_alteo':
          return 'facture_alteo_id';
        case 'facture':
          return 'facture_id';
        case 'ihs':
          return 'ihs_id';
        default:
          return this.champName;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.class-paiement-lcr {
  display: flex;
  justify-content: space-around;
  .editable-input {
    .edit-value {
      display: flex;
      .edit-action {
        .action-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          margin: 0 1px;
          margin-left: 1px;
          margin-right: 1px;
          border: 1px solid #ded3d5;
          font-size: 7px;
          cursor: pointer;

          &.validate {
            color: #4d4bac;
            width: 20px;
            height: 20px;
            margin-bottom: 2px;
            &:hover {
              color: #ffffff;
              background-color: #4d4bac;
            }
          }

          &.cancel {
            color: #dc3c3c;
            &:hover {
              color: #ffffff;
              background-color: #dc3c3c;
            }
          }
        }
      }
    }
    .show-value {
      min-width: 10px;
      min-height: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .show-view-pencil {
        color: #9e9e9e;
        opacity: 0;
        display: none;
        transition: opacity 0.2s ease;
      }
      .show-editable-icon {
        display: inline-block;
        opacity: 1;
      }
      &:hover {
        .show-view-pencil {
          display: inline-block;
          opacity: 1;
        }
      }
    }
  }
}
.loader-data-lcr {
  height: calc(100vh - 157px);
  margin: auto;
  display: flex;
  .looping-rhombuses-spinner {
    margin: auto;
  }
}
.loader-data-lcr-item {
  position: absolute;
  display: block;
  top: 0;
  background: #efefef75;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  .looping-rhombuses-spinner {
    margin: auto;
    margin-top: 17%;
  }
}
.error-lcr {
  font-size: 9px;
  text-align: center;
  color: red;
}
.select-statut-block-val {
  display: flex;
  .selecty-comp {
    width: 80%;
  }
  .icon-block {
    width: 15%;
    margin-top: 34px;
    color: green;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
  .icon-block-d {
    width: 15%;
    margin-top: 36px;
    font-size: 16px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
.countLcrIcon {
  text-align: center;
  color: #fff;
  background-color: #9799d6;
  border-color: #6569d0;
  padding: 1px 3px;
  font-size: 9px;
  min-width: 14px;
  height: 14px;
  border: 1px solid #9799d6;
  box-shadow: 0px 3px 6px #0e0d0d1f;
  border-radius: 50px;
}
</style>
<style lang="scss">
.modal-paiement-lcr {
  display: flex;
  .col-modal-1 {
    width: 30%;
  }
  .col-modal-2 {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 0px;
    align-items: center;
    overflow-x: auto;
    justify-content: center;
    height: calc(100vh - 155px);
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
  .d-block {
    font-size: 13px;
  }
  .block-montant-lcr {
    display: flex;
    justify-content: center;
    .label-montant-lcr {
      margin-right: 10px;
      font-size: 17px;
      font-weight: 700;
    }
    .value-montant-lcr {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .item-cheque-lcr-clock {
    display: inline;
    // border: 1px solid #d9cdcd;
    margin: 5px;
    padding: 5px;
    width: 48%;
    border-radius: 11px;
    box-shadow: 2px 2px 2px #cebfbf;
    background-color: #f3f3f382;
    .sub-item-cheque-lcr-clock {
      display: flex;
    }
  }
}
.inputDatePaiement {
  width: 100% !important;
  .mx-input-wrapper {
    width: 100%;
  }
}
.selectDefault {
  min-width: 80px;
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input {
    background-color: transparent;
  }
}
.customSelectSingleModal {
  width: 97%;
  min-height: 29px !important;
  .multiselect__placeholder {
    font-size: 8px;
    margin-bottom: 0px;
  }
  .multiselect__tags {
    padding: 0px 31px 0 8px;
    min-height: 29px;
    border-radius: 50px;
  }
  .multiselect__select {
    width: 30px;
    height: 29px;
  }
  .multiselect__spinner {
    height: 27px;
    border-radius: 50px;
    width: 22px;
  }
  .multiselect__option {
    font-size: 8px;
    padding: 5px;
    line-height: 14px;
    min-height: 19px;
  }
  .multiselect__single {
    display: none;
  }
  .multiselect__input {
    font-size: 9px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
}
</style>
